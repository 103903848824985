import { render, staticRenderFns } from "./TableEditable.vue?vue&type=template&id=2d05f979&scoped=true&"
import script from "./TableEditable.vue?vue&type=script&lang=js&"
export * from "./TableEditable.vue?vue&type=script&lang=js&"
import style0 from "./TableEditable.vue?vue&type=style&index=0&id=2d05f979&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d05f979",
  null
  
)

export default component.exports